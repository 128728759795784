/* fonts */
@font-face {
  font-family: "source-sans-pro-v9-latin-regular";
  src:
    url("/fonts/source-sans-pro-v9-latin-regular.woff") format("woff"),
    url("/fonts/source-sans-pro-v9-latin-regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* fonts */
@font-face {
  font-family: "source-sans-pro-semibold";
  src:
    url("/fonts/source-sans-pro-semibold.woff") format("woff"),
    url("/fonts/source-sans-pro-semibold.svg") format("svg");
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: "inconsolata-v15-latin-regular";
  src:
    url("/fonts/inconsolata-v15-latin-regular.woff") format("woff"),
    url("/fonts/inconsolata-v15-latin-regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
