#mainContainer {
  max-width: 100% !important;
  width: 100%;
  padding: 0 10%;
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0;
  align-items: flex-start;
}

.blocks-section {
  flex-grow: 1;
  flex-basis: 250px;
}

.showmore {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  cursor: pointer;
  border-radius: 4px;
}

.showmore p {
  margin-bottom: 0;
  font-family: "Source Sans Pro", sans-serif;
  color: #8997a5;
  font-size: 14px;
}

.blocks-section .title {
  display: inline-block;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  margin-bottom: 30px;
}

.blocks-holder {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
}

.block {
  display: none;
  overflow: hidden;
  transition: 0.2s ease-in-out;
  border: 1px solid #c4cbd2;
  position: relative;
}

.block.visible {
  display: block;
}

.block-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.2em 0.5em;
  transition: 0.2s ease-in-out;
  overflow: hidden;
  font-size: 14px;
  border-bottom: 1px solid #c4cbd2;
}

.block-info .amount {
  flex-grow: 1;
  text-align: center;
}

.block-info a.color-code {
  font-family: "Source Sans Pro", sans-serif;
  line-height: 1;
  color: #989898;
  text-align: left;
}

.block-info span.timespan {
  font-family: "Source Sans Pro", sans-serif;
  line-height: 1;
  color: #989898;
  text-align: right;
}

.block-rows {
  display: flex;
  flex-direction: column;
  height: 90px;
  padding: 1px;
}

.block-rows .block-rewards > span {
  position: relative;
  flex-basis: 10px;
  flex-grow: 1;
}

.block-rows .block-rewards,
.block-rows .block-votes,
.block-rows .block-tickets,
.block-rows .block-transactions {
  flex-basis: 5px;
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  border-top: 1px solid white;
}

.block-rows .block-rewards {
  border-top: none;
}

.block-rows .block-rewards > span,
.block-rows .block-votes > span,
.block-rows .block-tickets > span,
.block-rows .block-transactions > span {
  border-left: 1px solid white;
}

.block-rows .block-votes > span,
.block-rows .block-tickets > span,
.block-rows .block-transactions > span {
  flex-basis: 5px;
  flex-grow: 1;
}

.block-rows .block-rewards > span:first-child,
.block-rows .block-votes > span:first-child,
.block-rows .block-tickets > span:first-child,
.block-rows .block-transactions > span:first-child {
  border-left: none;
}

.block-rows .block-rewards .paint {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #2ed6a1;
}

.block-rows .block-rewards .pow .paint {
  opacity: 0.4;
}

.block-rows .block-rewards .pos .paint {
  opacity: 0.6;
}

.block-rows .block-rewards .fund .paint {
  opacity: 0.8;
}

.block-rows .block-rewards > .fees {
  background-color: #2ed6a1;
}

.block-rows .block-element-link {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 1.3;
  color: #5a5a5a;
}

.block-rows .block-tickets .block-ticket {
  background-color: #ffc84d;
}

.block-rows .block-tickets .block-rev {
  background-color: #fd714a;
}

.block-rows .block-transactions .block-tx {
  background-color: #c4cbd2;
}

.block-rows .block-element-link,
.block-rows .block-element-link:hover {
  text-decoration: none;
}

#mainContainer .decimal {
  margin-left: 0;
}

.blockhchainWrapper {
  padding: 60px 0;
}

.blockhchainWrapper .title {
  font-family: "Source Sans Pro", sans-serif;
  color: #2970ff;
  font-size: 20px;
  margin-bottom: 30px;
}

body.darkBG {
  .blocks-section .showmore {
    background: #2d2d2d !important;
  }

  .blocks-holder .info-block {
    background: #2d2d2d !important;
  }

  .blocks-holder .info-block .color-code {
    color: rgba(148 255 202 / 75%) !important;
  }

  .block-rows .block-rewards,
  .block-rows .block-votes,
  .block-rows .block-tickets,
  .block-rows .block-transactions {
    border-top: 1px solid black;
  }

  .block-rows .block-rewards > span,
  .block-rows .block-votes > span,
  .block-rows .block-tickets > span,
  .block-rows .block-transactions > span {
    border-left: 1px solid black;
  }

  .block-info a.color-code,
  .block-info span.timespan {
    color: #bbb;
  }

  .block-info a.color-code:hover {
    color: $dark-link-hover-color;
  }
}

.blockhchainWrapper table#blockchainTable {
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.blockhchainWrapper table#blockchainTable tr {
  border-bottom: 1px solid #d1d7dc;
}

.blockhchainWrapper table#blockchainTable tr.heading {
  background: #fff;
  color: #17173c;
  height: 40px;
}

.blockhchainWrapper table#blockchainTable tr.data {
  font-family: "Inconsolata", monospace;
  height: 50px;
  cursor: pointer;
  transition: 0.1s linear;
}

.blockhchainWrapper table#blockchainTable tr.data td.blocks {
  color: #2970ff;
}

.blockhchainWrapper table#blockchainTable tr.data td.selector {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
}

.blockhchainWrapper table#blockchainTable tr.data td.selector span {
  position: absolute;
  background: none;
  border: 2px solid #2970ff;
  width: 20px;
  height: 20px;
  border-radius: 50px;
}

.blockhchainWrapper table#blockchainTable tr.data td.selector span.clicked {
  background: #2970ff;
}

.blockhchainWrapper table#blockchainTable tr.data:hover {
  background: #eee;
}

.blockRewardsWrapper,
.proofofstakeWrapper {
  padding-right: 50px;
}

.tippy-tooltip.light-theme {
  color: #26323d;
  box-shadow:
    0 0 20px 4px rgba(154 161 177 / 15%),
    0 4px 80px -8px rgba(36 40 47 / 25%),
    0 4px 4px -2px rgba(91 94 105 / 15%);
  background-color: #fff;
}

.tippy-popper[x-placement^=top] .tippy-arrow {
  border-top-color: white;
}

@media (max-width: 1070px) {
  .blocks-section {
    margin-top: 0;
  }
}

@media (min-width: 800px) {
  header .right .searchbox.full input {
    flex: 1;
  }
}

@media (max-width: 600px) {
  header .right .searchbox input {
    width: 150px;
  }
}

@media only screen and (max-width: 768px) {
  #mainContainer {
    padding: 0 5%;
  }
}

@media only screen and (max-width: 576px) {
  .blocks-section {
    padding: 0;
  }
}
