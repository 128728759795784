/* keyboard navigation */
.keys-hint {
  color: gray;
  font-size: 12px;
  position: relative;
  top: -3px;
  left: 9px;
}

.keys-hint .arrows {
  display: inline-block;
  line-height: 6px;
  top: 4px;
  position: relative;
}

a {
  outline-color: transparent;
}

.keynav-target {
  animation-duration: 0.2s;
  animation-name: highlight;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  outline: 3px dotted transparent;
}

.keynav-target.activated {
  animation-duration: 0.8s;
  animation-fill-mode: none;
  animation-iteration-count: infinite;
}

.keynav-target.pulsate {
  animation-delay: 0.1s;
  animation-duration: 0.32s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

body.darkBG .keynav-target {
  animation-name: green-highlight;
}

@keyframes highlight {
  0% { outline-color: #4582fe29; }

  50% { outline-color: #2970ff7a; }

  100% { outline-color: #4582fe29; }
}

@keyframes green-highlight {
  0% { outline-color: #8defbf3b; }

  33% { outline-color: #8defbf99; }

  100% { outline-color: #8defbf3b; }
}
