/**  testnet theme  **/
body.theme-testnet {
  .top-nav,
  .navbar-fixed-bottom {
    background: #116466;
    color: #fff;
  }

  .top-search {
    color: #fff;
  }

  #menu-toggle .patty {
    background: #fff;
  }

  .dcrdata-logo {
    background-image: url("/images/themes/night/logo.svg");
  }
}

/**  simnet theme  **/
body.theme-simnet {
  .top-nav,
  .navbar-fixed-bottom {
    background: #076191;
    color: #fff;
  }

  .top-search {
    color: #fff;
  }

  #menu-toggle .patty {
    background: #fff;
  }
}

/* dark theme */
body.darkBG {
  background: #3b3f45;
  color: #fdfdfd;

  .grayed {
    color: rgba(253 253 253 / 50%);
  }

  a,
  a:hover {
    color: $dark-link-hover-color;
  }

  a.elidedhash,
  a.elidedhash:hover {
    color: transparent;
    text-decoration-color: $dark-link-hover-color;
  }

  a.elidedhash::before,
  a.elidedhash::after {
    color: $dark-link-hover-color;
  }

  div.elidedhash::before,
  div.elidedhash::after {
    color: #fdfdfd;
  }

  a.grayed,
  a.grayed:hover {
    color: rgba(148 255 202 / 75%) !important;
  }

  table thead th {
    vertical-align: bottom;
  }

  table thead tr {
    background-color: #2d2d2d;
  }

  table tr.disabled-row {
    background-color: #ff00000d;
  }

  table.table {
    tr,
    th,
    td {
      color: #fdfdfd; // Set this explicitly to prevent bootstrap > 4.3.1 override
      border-bottom: 1px solid #2d2d2d;
    }
  }

  table.v3 thead tr {
    background-color: inherit;
    color: #c1c1c1;
  }

  .top-nav {
    box-shadow: 0 0 25px #000;
  }

  .top-nav,
  .navbar-fixed-bottom {
    background: #292929;
  }

  .top-nav a,
  .navbar-fixed-bottom a {
    color: #fff;
  }

  .page-link {
    background-color: rgba(255 255 255 / 18%);
    border: 1px solid rgba(221 221 221 / 0%);
  }

  .page-item.disabled .page-link {
    border-color: transparent;
    background-color: #565656;
  }

  .json-block {
    color: #fdfdfd;
  }

  .top-search {
    color: #7fbdff;
  }

  .top-nav input:autofill {
    animation-name: autofill-dark;
    animation-fill-mode: both;
  }

  #menu-toggle .patty {
    background: #fdfdfd;
  }

  #menu-toggle input:checked ~ .patty {
    background: #fbfbfb;
  }

  #menu .menu-item {
    color: #fbfbfb;

    &:hover,
    &:hover span {
      color: $dark-link-hover-color;
    }
  }

  #menu .menu-form:hover .menu-item {
    color: $dark-link-hover-color;
  }

  #menu {
    background: #2a2a2a;
    box-shadow: 0 0 0 1px #fff3;
  }

  .progress {
    background-color: #757575;
  }

  select,
  input[type=number] {
    color: #fff;
    background: #2a2a2a;
    border-color: #191919;
  }

  .nav-pills .nav-link.active {
    background-color: #2dd8a3;
    color: black;
  }

  .dcrdata-logo {
    background-image: url("/images/themes/night/logo.svg");
  }

  // bootstrap dark theme
  .bg-white {
    background-color: $inverse-bg-white !important;
  }

  .text-secondary {
    color: #c1c1c1 !important;
  }

  .text-black-50 {
    color: rgba(255 255 255 / 50%) !important;
  }

  .text-green {
    color: #2eefa1;
  }

  .text-abandoned,
  .text-blueish {
    color: #c4cbd2;
  }

  .unstyled-link,
  .unstyled-link:hover,
  .unstyled-link:visited {
    color: #fff;
  }

  .block-banner .text-secondary:hover {
    color: #fff !important;
  }
}
