/* Typography */
.fs14-decimal .dot,
.fs14-decimal .decimal,
.fs14-decimal .unit {
  font-size: 14px;
}

.fs16-decimal .dot,
.fs16-decimal .decimal,
.fs16-decimal .unit {
  font-size: 16px;
}

.medium-sans {
  font-family: "source-sans-pro-semibold", sans-serif;
}

.sep {
  position: relative;
  top: 3px;
  width: 1px;
  height: 14px;
  background: #848484;
  display: inline-block;
  margin: 0 3px;
}

.hash {
  font-size: 14px;
  font-family: "inconsolata-v15-latin-regular", monospace;
  display: inline-block;
  word-wrap: break-word;
  transition: 0.133s transform;
}

.truncate {
  max-width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mono {
  font-family: "inconsolata-v15-latin-regular", monospace;
}

.unstyled-link,
.unstyled-link:hover,
.unstyled-link:visited {
  text-decoration: none;
  color: #052f4b;
}

.block-banner .text-secondary:hover {
  color: #052f4b !important;
}

.elidedhash {
  position: relative;
  display: inline-block;
  font-family: "inconsolata-v15-latin-regular", monospace;
  color: transparent;
  max-width: 100%;
  -webkit-user-select: all;
  -moz-user-select: all;
  user-select: all;
  z-index: 20;
  word-break: break-all;
  overflow: hidden;
  height: 1.2em;
  padding-top: 1px;
}

a.elidedhash {
  cursor: pointer;
}

a.elidedhash:visited {
  color: transparent;
}

.elidedhash::selection {
  color: transparent;
  background-color: #ccc;
}

.elidedhash::before {
  content: attr(data-head);
  position: absolute;
  left: 0;
  top: 2px;
  right: 3em;
  word-break: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  z-index: 10;
  text-align: right;
}

a.elidedhash::before,
a.elidedhash::after {
  color: #2e75ff;
}

div.elidedhash::before,
div.elidedhash::after {
  color: #212529;
}

.elidedhash::after {
  content: attr(data-tail);
  position: absolute;
  right: 0;
  top: 2px;
  width: 3em;
  z-index: 10;
  word-break: normal;
  text-align: right;
  direction: rtl;
}

a.elidedhash:hover {
  color: transparent;
  text-decoration: underline;
  text-decoration-color: #2e75ff;
}

.valign-top {
  vertical-align: top;
}

// colors

.grayed {
  opacity: 0.5;
}

body .grayed {
  color: rgba(33 37 41 / 50%);
}

a.grayed {
  color: rgba(46 117 255 / 75%) !important;
}

/* content */
.dcr-total .dcr {
  font-size: 28px;
}

.dcr-total .total-text {
  line-height: 11px;
  opacity: 0.8;
  font-weight: bold;
  letter-spacing: 0;
}

.dcr-total .total-text .d-block {
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 13px;
}

.attention {
  color: red;
  font-size: 14px;
}

.attention:hover {
  color: #f55;
}

.text-green {
  color: #00a100;
}

.text-red {
  color: #ef2e2e;
}

.text-progress {
  color: #2970ff;
}

.text-no-quorum {
  color: #feb8a5;
}

.text-failed {
  color: #ed6d47;
}

.text-abandoned,
.text-blueish {
  color: #3d5873;
}

// numbers
.decimal-parts {
  .int {
    padding-right: 0.1em;
  }

  .dot {
    padding-right: 0.1em;
  }
}

.fw-bold .decimal-parts .int {
  padding-right: 0.2em;
}
