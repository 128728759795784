.dygraph-legend {
  position: absolute;
  font-size: 13px;
  z-index: 10;
  background: white;
  line-height: normal;
  text-align: left;
  overflow: hidden;
  padding: 4px !important;
  border: 1px solid #000;
  border-radius: 5px;
  font-weight: bold;
  width: fit-content !important;
  height: fit-content !important;
}

.dygraph-rangesel-bgcanvas {
  z-index: 0 !important;
}

.dygraph-rangesel-fgcanvas {
  z-index: 0 !important;
}

.dygraph-rangesel-zoomhandle {
  height: 16px;
  width: 8px;
  z-index: 0 !important;
}

.dygraph-axis-label {
  z-index: 0 !important;
  z-index: 10;
  line-height: normal;
  overflow: hidden;
  color: black;
}

.tp-charts {
  width: 100%;
  height: 25vh;
  margin: 0 auto;
  font-size: 14px;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  background:
    rgba(214 225 231 / 30%)
    url("/images/loader.gif")
    50% 50%
    no-repeat;
}

.loading .modal {
  display: block;
}

.loader-v2 {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  z-index: 20;
  background-color: rgba(128 128 128 / 40%);
}

.loader-v2::after {
  content: "";
  width: 70px;
  height: 70px;
  background-image: url("/images/loader.svg");
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-animation: spin 0.8s ease-in-out infinite; /* Safari */
  animation: spin 0.8s ease-in-out infinite;
  opacity: 0.6;
}

.loader-v2.loading {
  display: flex;
}

.bal-d {
  font-weight: bolder;
  margin: 0 auto;
  text-align: center;
}

.btn_sm {
  padding: 0 0.5rem !important;
  font-size: 0.875rem !important;
  background-color: #fff !important;
  background-repeat: repeat-x;
  text-shadow: 0 1px 1px rgba(255 255 255 / 69%);
  -webkit-font-smoothing: antialiased;
  border-style: solid;
  border-color: rgba(0 0 0 / 50%) !important;
  height: fit-content;
}

.btn-set {
  border-top-style: solid;
  border-width: 1px;
  border-color: #333;
  position: relative;
  padding-top: 0.6em;
  margin: 1em 0;
  font-size: 14px;
}

.btn-set > button {
  box-sizing: content-box;
  padding: 0 0.2em;
  margin: 0 0.1em;
  height: 1.5em;
  line-height: 1.5em;
  border-style: none;
  cursor: pointer;
  border-radius: 2px;
  background: transparent;
  transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.btn-set > button:focus {
  outline: none;
}

.btn-set > button.btn-selected {
  background-color: white;
  border: 1px solid #aaa;
}

.btn-set > label {
  position: absolute;
  top: -0.7em;
  line-height: 1em;
  font-weight: bolder;
  background-color: inherit;
  z-index: 10;
  padding-right: 0.3em;
  font-size: 13px;
}

.btn-active,
.btn:active {
  background-color: hsl(195deg 79% 43%) !important;
  background-repeat: repeat-x;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#56c5eb", endColorstr="#1798c4");
  background-image: -khtml-gradient(linear, left top, left bottom, from(#56c5eb), to(#1798c4));
  background-image: -moz-linear-gradient(top, #56c5eb, #1798c4);
  background-image: -ms-linear-gradient(top, #56c5eb, #1798c4);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #56c5eb), color-stop(100%, #1798c4));
  background-image: -webkit-linear-gradient(top, #56c5eb, #1798c4);
  background-image: -o-linear-gradient(top, #56c5eb, #1798c4);
  background-image: linear-gradient(#56c5eb, #1798c4);
  color: #333 !important;
  text-shadow: 0 1px 1px rgba(255 255 255 / 33%);
  -webkit-font-smoothing: antialiased;
  border-style: solid;
  border-color: rgba(0 0 0 / 15%) !important;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.chart-btn-display,
.block-chart-btn-display {
  display: none;
  line-height: 0.8;
}

.dygraph-legend-line {
  display: inline-block;
  position: relative;
  bottom: 0.5ex;
  padding-left: 1em;
  height: 1px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.dygraph-legend-dash {
  display: inline-block;
  position: relative;
  bottom: 0.5ex;
  height: 1px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
}

.dygraph-roller {
  position: absolute;
  z-index: 10;
}

.dygraph-title {
  font-weight: bold;
  z-index: 10;
  text-align: center;
  font-size: 14px;
}

.dygraph-xlabel {
  text-align: center;
}

.market-chart-box {
  position: relative;
  min-height: 225px;
}

.market-chart {
  .dygraph-xlabel {
    text-align: center;
    padding: 0.3em 0;
  }

  .dygraph-ylabel {
    position: relative;
    bottom: 1.5em;
  }

  .dygraph-ylabel,
  .dygraph-xlabel {
    font-size: 13px;
  }
}

@media (min-width: 992px) {
  .market-chart {
    .dygraph-ylabel,
    .dygraph-xlabel {
      font-size: 16px;
    }
  }
}

.dygraph-label-rotate-left {
  text-align: center;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.dygraph-label-rotate-right {
  text-align: center;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.chartview {
  width: 96%;
  min-height: 73vh;
  margin: 0 2%;
}

.chartview .dygraph-ylabel {
  color: #2970ff;
}

body.darkBG .chartview .dygraph-ylabel {
  color: #2dd8a3;
}

.chartview .dygraph-y2label {
  color: #066;
}

body.darkBG .chartview .dygraph-y2label {
  color: #2970ff;
}

#tickets-by-purchase-date .dygraph-ylabel {
  color: #2971ff;
  font-weight: bold;
}

#tickets-by-purchase-date .dygraph-y2label {
  color: #ff0090;
  font-weight: bold;
}

.address_chart_wrap {
  background-color: white;
  border-radius: 3px;
  flex: auto;
  position: relative;
  min-height: 200px;
  border: 1px solid rgba(128 128 128 / 50%);
}

.address_chart {
  position: absolute;
  left: 1em;
  right: 2em;
  top: 1em;
  bottom: 1em;
}

/* chart theme */
.chart-wrapper {
  position: relative;
}

.chart-wrapper.loading .spinner-wrapper {
  display: block;
}

.chart-control-wrapper {
  border: 1px solid #e6eaed;
  border-left: 1px solid #e6eaed;
  font-size: 0.9rem;
}

.chart-control-label {
  font-size: 14px;
  font-weight: 600;
  color: #8997a5;
  text-align: center;
  border-bottom: 1px solid #e6eaed;
}

.chart-control {
  padding: 2px;
  background: #fff;
  width: max-content;
}

.chart-form-control {
  width: -moz-fit-content;
  width: fit-content;
  font-size: 0.9rem !important;
  height: calc(1.5em + 0.5rem + 2px) !important;
  line-height: 1;
}

.chart-control select,
.chart-control input {
  border: none;
}

.chart-control input {
  height: 38px;
}

.legend-wrapper {
  position: absolute;
  top: -2.5rem;
  left: 100px;
  right: 20px;
}

.legend {
  background: #ececec;
  padding: 5px 10px 5px 20px;
  font-size: 14px;
}

.darkBG .dygraph-axis-label {
  color: #c4cbd2;
}

.darkBG .chart-control {
  background: #2b2b2b;
}

.darkBG .chart-control-label,
.darkBG .chart-control-wrapper {
  color: #eeeeef;
  border-color: #2b2b2b;
}

.darkBG .legend {
  background: #2a2a2a;
}

.darkBG .dygraph-legend {
  background: #e6d9d9;
  color: #2d2d2d;
}

.align-bottom {
  position: relative;
}

.address-btn {
  margin-bottom: 5px !important;
}

.d-hide {
  display: none !important;
}

.customcheck {
  display: block;
  position: relative;
  padding-left: 20px;
  margin: 0.3rem auto;
  border: black;
  cursor: pointer;
  width: unset;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 1.5em;
}

.customcheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0.25em;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 8px;
}

input ~ .checkmark {
  background-color: #ccc;
}

.customcheck input:checked ~ .checkmark {
  border-radius: 8px;
}

.customcheck input:checked ~ .received {
  background-color: #2971ff;
}

.customcheck input:checked ~ .sent {
  background-color: #2ed6a1;
}

.customcheck input:checked ~ .net {
  background-color: #ff0090;
}

.customcheck input:checked ~ .tickets-price,
.customcheck input:checked ~ .coin-supply {
  background-color: #2970ff;
}

body.darkBG .customcheck input:checked ~ .tickets-price,
body.darkBG .customcheck input:checked ~ .coin-supply {
  background-color: #2dd8a3;
}

.customcheck input:checked ~ .tickets-bought,
.customcheck input:checked ~ .total-mixed {
  background-color: #066;
}

body.darkBG .customcheck input:checked ~ .tickets-bought,
body.darkBG .customcheck input:checked ~ .total-mixed {
  background-color: #2970ff;
}

.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

.customcheck .checkmark::after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.customcheck input:checked ~ .checkmark::after {
  display: block;
}

.chart-box {
  font-size: 14px;
  background-color: #fff;
  height: 2em;
  line-height: 2em;
  padding-right: 0.5em;
  border: 1px solid gray;
}

.chart-box:focus {
  outline: none;
}

.loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #3498db;
  width: 50px;
  height: 50px;
  display: block;
  float: right;
  margin: 20%;
  -webkit-animation: spin 0.9s linear infinite; /* Safari */
  animation: spin 0.9s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }

  100% { transform: rotate(480deg); }
}

.d-val {
  font-size: 10px;
  font-weight: bold;
  text-align: right;
}

.c-val {
  background-color: #d4dfe4;
}

.img-val {
  height: 30px;
  margin: 2px;
}

.meter {
  display: inline-block;
  width: 110px;
  height: 110px;
  font-size: 14px;
  overflow: hidden;
}

.lilbox {
  height: 45px;
}

.meter.lil {
  width: 80px;
  height: 45px;
}

.meter.large-gap {
  height: 80px;
}

.meter > canvas {
  width: 110px;
  height: 110px;
}

.meter.lil > canvas {
  width: 80px;
  height: 80px;
}

.market-chart-legend {
  position: absolute;
  top: 5px;
  font-size: 14px;
  right: 10px;
  padding: 0.2em 1em;
}

.market-chart-title {
  position: absolute;
  top: 5px;
  left: 65px;
  font-size: 22px;
  padding: 0 0.4em;
  z-index: 10;
  background-color: #fffa;
  border: 1px solid #7774;
}

.proposal-chart-legend {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 2px;
  font-size: 14px;
  border-radius: 0;
  background-color: #fffa;
}

body.darkBG {
  .address_chart_wrap {
    background-color: #333;
  }

  .btn-set {
    border-color: #ddd;
  }

  .btn-set > button {
    background-color: #333;
    color: white;
  }

  .btn-set > button.btn-selected {
    padding: 0.2 0.7em;
    background-color: #34a7;
  }

  .market-chart-title,
  .proposal-chart-legend {
    background-color: #333a;
  }
}

.proposal-charts:hover .proposal-chart-legend {
  display: block;
}

.proposal-chart-align {
  height: 23vh;
}

.tp-outputs-align {
  width: inherit;
}
